/**
 * This function can be used to be strip away any html
 * @param {HTMLElement|string} html input can be markup or a simple string
 * @returns {string} it will return a string value stripped of html
 */
export function sanitizeHtml(html) {
    let temporary = document.createElement('div');
    temporary.insertAdjacentHTML('afterbegin', html);
    return temporary.textContent || '';
}

/**
 * Converts a html string to usable Js element
 * Operation is dangerous in its current state.
 * @param {string} htmlAsString
 * @returns {HTMLElement}
 */
export function dangerouslyConvertHtmlStringToElement(htmlAsString) {
    const template = document.createElement('template');
    htmlAsString = htmlAsString.trim(); // Never return a text node of whitespace as the result
    template.insertAdjacentHTML('afterbegin', htmlAsString);
    return template.firstElementChild;
}
